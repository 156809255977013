import Date from '../components/Date.js'

function Footer() {
  return (
    <div className='footer'>
      <p className='tag'>MANCHESTER – UK</p>
      <ul className='links spacer60Bottom'>
        <li className='strike'>Read.cv</li>
        <li className='strike'>Are.na</li>
        <li className='strike'>LinkedIn</li>
        <li className='strike'>Behance</li>
        <li className='strike'>Twitter</li>
        <li className='strike'>Instagram</li>
        <li className='strike'>VSCO</li>
        <li className='strike'>Substack</li>
        <li className='strike'>Dribble</li>
        <li className='strike'>The Dots</li>
        <li className='strike'>Medium</li>
      </ul>
      <ul className="links flex">
        <li>Site Ver 0.1</li>
        <li className='strike'>Glossary</li>
        <li className='strike'>Visual Collections – 01</li>
        <Date />
      </ul>
    </div>
  )
}

export default Footer
