import arrow from'../arrow.svg';
 
const Header = () => {
  return (
    <div className='header flex'>
      <p className="links">J Wrigley</p>
      <ul className="links">
        <li className='strike'>Works</li>
        <li className='strike'>Writings</li>
        <li className='strike'>Projects</li>
        <li className='strike'>Info</li>
        <img className='arrow' src={arrow} alt="" />
      </ul>
    </div>
  )
}

export default Header
