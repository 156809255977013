function Body() {
  return (
    <div className="bodyContainer spacer160Bottom spacer160Top">
      <div className="bodyContentBlock">
        <p>DESIGN, WRITE, TEACH</p>
        <p>I am an artist, designer, writer and creative director specialising in visual communication and digital cultures. My practice spans, design, writing, photography and education. As designer I focus on printed publications, digital platforms and explore archives, curation and concepts surrounding the gathering and dissemination of information. I write about photography and the role that design and visual communication can play in exploring and interpreting social, cultural, and anthropological themes. I am interested in collaborative creativity, mark making, communal creation and curation, exploring new technologies and software, designing for print, developing for the web and creating projects that collect people, things, ideas or concepts together.</p>
        <p>Lecturer in Visual Communication & Digital Cultures at Manchester Metropolitan Universities, Fashion Institute.</p>
        <br></br>
        <br></br>
        <p>SITE UNDER CONSTRUCTION</p>
      </div>
      <div>
        <p className="links">[hello@jameswrigley.co.uk]</p>
      </div>
    </div>
  )
}

export default Body
