export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let min = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    
    return (
        <li>Visited: {hours}:{min}:{seconds} – {date}/{month<10?`0${month}`:`${month}`}/{year}</li>
    )
}
export default getCurrentDate